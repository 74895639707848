// src/hooks/useApolloClient.js
import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'

export const useApolloClient = (language) => {
    return useMemo(() => {
        const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URI })

        const authLink = new ApolloLink((operation, forward) => {
            // Modify the request URI based on the language
            operation.setContext({
                uri: `${import.meta.env.VITE_GRAPHQL_URI}`
            })

            return forward(operation)
        })

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
        })
    }, [language]) // Recreate the client only when language changes
}
