import { motion } from "framer-motion"

const ContactCtaLarge = () => {
    return <>
        <section className="section-home-call-to-action">
            <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-small">
                    <div className="content-block-item">
                        <div className="w-layout-grid call-to-action-component-grid">
                        <div className="inner-grid-block">
                            <div className="cta-wrapper-flex">
                            <motion.div
                                animate={{
                                scale: [1, 1.2, 1.2, 1, 1],
                                rotate: [0, 360]
                                }}
                                transition={{
                                    duration: 2,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                    repeat: Infinity,
                                    repeatDelay: 1
                                }}>
                                <a href="/contact" className="cta-button-link w-inline-block">
                                    <img src="/img/get-in-touch.svg" alt="Get In Touch" className="circle-text" />
                                    <div className="cta-arrow">keyboard_return</div>
                                    <div className="circle-button"></div>
                                </a>
                            </ motion.div>
                            </div>
                        </div>
                        <div className="cta-flex">
                            <h2 className="heading-style-h1 text-align-center"><span className="asterisk">✺</span> </h2>
                            <h2 className="heading-style-h1 text-align-center">Let's work together</h2>
                            <h2 className="heading-style-h1 text-align-center"><span className="asterisk">✺</span> </h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default ContactCtaLarge