import Home from '../pages/Home'
import Projects from '../pages/Projects'
import Project from '../pages/Project'
import Contact from '../pages/Contact'
// import About from '../pages/About'
import Blog from '../pages/Blog'
// import Loading from '../components/Loading'
import Privacy from '../pages/Privacy'

import { useContext } from 'react'

import { ApolloProvider } from "@apollo/client"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import LanguageContext from '../contexts/LanguageContext'; // Assuming this is the path to your context

import { useApolloClient } from '../hooks/useApolloClient'

const Wrapper = () => {
    const { language } = useContext(LanguageContext)
    const client = useApolloClient()

    return <>
        <ApolloProvider client={ client }>
            <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/projects' element={<Projects />} />
                    <Route path='/projects/:id/:serviceid' element={<Project />} />
                    <Route path='/contact' element={<Contact />} />
                    {/* <Route path='/about' element={<About />} /> */}
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/blog' element={<Blog />} />
                    {/* <Route path='/loading' element={<Loading />} /> */}
                </Routes>
            </Router>
        </ApolloProvider>
        </>
}

export default Wrapper