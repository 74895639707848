import { useRef } from "react"

import { motion, useTransform, useAnimationFrame, useMotionValue } from "framer-motion"
import { wrap } from "@motionone/utils"

const ScrollingText = ({ children, baseVelocity }) => {
    const baseX = useMotionValue(0)

    // magic motion wrapping
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);
    const directionFactor = useRef(1)

    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * (delta / 1000);
        moveBy += directionFactor.current * moveBy * baseVelocity

        baseX.set(baseX.get() + moveBy);
    })

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */

    return (
        <div className="padding-global">
            <div className="container-large">
                <div className="padding-section-small">
                    <div className="parallax">
                        <motion.div className="scroller scrolling-text-xl" style={{ x }}>
                            <span>{ children }</span>
                            <span>{ children }</span>
                            <span>{ children }</span>
                            <span>{ children }</span>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ScrollingText