import Topbar from '../components/Topbar'
import Footer from '../components/Footer'
import ScrollingText from '../components/ScrollingText'
import ProjectItem from '../components/ProjectItem'
import Loading from '../components/Loading'
import ContactCtaLarge from '../components/ContactCtaLarge'
import ScrollingLogo from '../components/ScrollingLogo'

import { useContext } from 'react'

import { useQuery, gql } from "@apollo/client"
import LanguageContext from '../contexts/LanguageContext'; // Update with your actual path

const QUERY = gql`
  query GetHomepageData($locale: I18NLocaleCode!) {
    homepage(locale: $locale) {
      data {
        attributes {
          intro_text
          client_text
          offer_text
          about_us_main_text
          about_us_title
          services_button
          footer_text
          social_links
          scrolling_text
          homepage_video {
            data {
              attributes {
                url
              }
            }
          }
          client_logos {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    projects(sort: "sort_order") {
      data {
        id
        attributes {
          project_name
          category
          year
          link
          overview
          challenge
          result
          service {
            data {
              id
              attributes {
                service_name
                service_description
              }
            }
          }
          sort_order
          header_image {
            data {
              attributes {
                url
              }
            }
          }
          thumbnail_image {
            data {
              attributes {
                url
              }
            }
          }
          media {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    services(sort: "sort_order") {
      data {
        id
        attributes {
          service_name
          service_description
          sort_order
        }
      }
    }
    servicepage {
      data {
        attributes {
          intro_text
          page_title
        }
      }
    }
  }
`

const Home = () => {
    const { language } = useContext(LanguageContext)
    console.log(language)

    const { data, loading, error } = useQuery(QUERY, {
        variables: { locale: language },
    })

    if (loading) return <Loading />
    if (error) return <pre>{error.message}</pre>

    // console.log(data)

    const intro_text = data.homepage.data.attributes.intro_text ? data.homepage.data.attributes.intro_text : ""
    const client_text = data.homepage.data.attributes.client_text ? data.homepage.data.attributes.client_text : ""
    const offer_text = data.homepage.data.attributes.offer_text ? data.homepage.data.attributes.offer_text : ""
    const about_us = data.homepage.data.attributes.about_us_main_text ? data.homepage.data.attributes.about_us_main_text : ""
    const scrolling_text = data.homepage.data.attributes.scrolling_text ? data.homepage.data.attributes.scrolling_text : ""
    const client_logos = data.homepage.data.attributes.client_logos.data ? data.homepage.data.attributes.client_logos.data : [""]
    const footer_text = data.homepage.data.attributes.footer_text ? data.homepage.data.attributes.footer_text : ""
    const video_url = data.homepage.data.attributes.homepage_video.data.attributes.url ? data.homepage.data.attributes.homepage_video.data.attributes.url : ""
    const services = data.services.data ? data.services.data : [""]
    const projects = data.projects.data ? [...data.projects.data] : null;
    const about_us_title = data.homepage.data.attributes.about_us_title ? data.homepage.data.attributes.about_us_title : ""
    const services_button = data.homepage.data.attributes.services_button ? data.homepage.data.attributes.services_button.toUpperCase() : ""

    return <>
            <div className="page-wrapper">
                <Topbar />
                <main className="main-wrapper">
                    <header className="section-home-header">
                        <div className="padding-global">
                            <div className="container-large">
                                <div className="padding-section-header">
                                    <div className="w-layout-grid header-component-grid">
                                        <div id="w-node-cb88e35d-9200-2668-ed05-f21bf8d563a9-cb0f6d5c" className="content-block-item">
                                            <div className="w-layout-grid inner-content-grid">
                                                <div id="w-node-_3020776a-22e9-dded-619d-30b7f86d9301-cb0f6d5c" className="inner-grid-block">
                                                    <h1><span></span>{ intro_text }</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="w-node-f05ebaed-7a1d-f880-e300-8fdf20dc82ee-cb0f6d5c" className="content-block-item no-padding">
                                            <div className="video-block">
                                                <div className="video w-background-video w-background-video-atom">
                                                    <video id="ea31a790-04b8-4003-5630-8efeb759a071-video" autoPlay playsInline controls loop muted>
                                                        <source src={ video_url } />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="w-node-_327922a8-9da4-b439-ddb0-fe3cec141d0c-cb0f6d5c" className="w-layout-grid header-component-grid">
                                            <div id="w-node-ef592771-e271-4150-d923-c182db11310f-cb0f6d5c" className="content-block-item">
                                                <div className="w-layout-grid inner-content-grid">
                                                    <div className="inner-grid-block">
                                                        <h2 className="heading-style-h3">{ client_text ? client_text : null }</h2>
                                                    </div>
                                                </div>
                                                <ScrollingLogo client_logos={ client_logos } baseVelocity={ -4 } />
                                            </div>
                                            <a id="w-node-_1034f734-882e-0943-e09c-c3ea06270330-cb0f6d5c" data-w-id="1034f734-882e-0943-e09c-c3ea06270330" href="#services" className="content-block-link w-inline-block">
                                                <div className="w-layout-grid inner-content-grid small">
                                                <div id="w-node-_45426235-fb5b-c3e1-7971-0f9ff23fd666-cb0f6d5c" className="inner-grid-block">
                                                    <h2 className="heading-style-h4">{ offer_text ? offer_text : null }</h2>
                                                </div>
                                                <div id="w-node-_45426235-fb5b-c3e1-7971-0f9ff23fd669-cb0f6d5c" className="inner-grid-block flex">
                                                    <div>{ services_button }</div>
                                                    <div className="arrow-circle-dark">
                                                    <div className="arrow-dark">arrow_forward</div>
                                                    </div>
                                                </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                <section>
                <ScrollingText baseVelocity={ 2 }>{ scrolling_text }</ScrollingText>
                </section>
                <section className="section-home-about">
                    <div className="padding-global">
                        <div className="container-large">
                            <div className="padding-section-small">
                            <div className="content-block-item">
                                <div className="w-layout-grid about-component-grid">
                                    <div className="inner-grid-block">
                                        <div className="text-size-medium">• { about_us_title }</div>
                                        <div className="margin-top margin-large">
                                    </div>
                                </div>
                                <div className="inner-grid-block">
                                    <h3 className="heading-style-h2">{ about_us }</h3>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    <section id="services" className="section-about-services">
                    <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-small">
                        <div className="content-block-item">
                            <div className="text-size-medium">• Services</div>
                            <div className="margin-top margin-xlarge">
                            <div className="w-layout-grid service-component-grid">
                                { services.map((service) => (
                                    <div key={ service.id } className="content-block-item small">
                                        <div className="w-layout-grid inner-content-grid small">
                                            <div className="inner-grid-block">
                                            <div className="service-icon">blur_on</div>
                                            </div>
                                            <div className="inner-grid-block">
                                            <div className="text-size-large">{ service.attributes.service_name }</div>
                                            <div className="margin-top margin-small">
                                                <p className="text-size-small">{ service.attributes.service_description }</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="section-home-projects">
                    <div className="padding-global">
                        <div className="container-large">
                            <div className="padding-section-small">
                                <div className="content-block-item">
                                    <div className="text-size-medium">• Selected Projects</div>
                                    <div className="margin-top margin-large">
                                        <div role="list" className="project-component-grid w-dyn-items">
                                            {
                                                projects ?
                                                projects.map((project) => (
                                                    <ProjectItem key={ project.id } props={ project } />
                                                ))
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactCtaLarge />
                </main>
                <Footer text={ footer_text } />
            </div>
            <Topbar />
        </>
}

export default Home