import { motion } from "framer-motion"

const Loading = () => {
    return <>
        <div className="loading">
        <motion.div
            className="loading-content"
            animate={{
                scale: [1, 2, 1],
                rotate: [0, 180, 180, 0],
                borderRadius: ["0%", "0%", "50%", "50%", "0%"]
            }}
            transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 1
            }}
            >
                <div className="loading-text">
                    Loading
                </div>
            </motion.div>
        </div>
    </>
}

export default Loading