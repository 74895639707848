import { useState, useEffect, useRef } from "react"
import { motion, useTransform, useAnimationFrame, useMotionValue } from "framer-motion"
import { wrap } from "@motionone/utils"

const ScrollingLogo = ({ client_logos, baseVelocity }) => {
    const baseX = useMotionValue(0)
    const [width, setWidth] = useState(0)
    const [repeat, setRepeat] = useState(4)

    // magic motion wrapping
    const x = useTransform(baseX, (v) => `${wrap(-0, -102.5, v)}%`);
    const directionFactor = useRef(1)

    // get the width of one row of logos
    useEffect(() => {
        setWidth(document.querySelector('.logo-row').offsetWidth)
        // get viewport width
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        // console.log(vw)
        // get the number of times to repeat the child text
        const repeat = Math.ceil(vw / width)
        // console.log(repeat)
        setRepeat(repeat)
    }, [])

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */

    // when the x value is greater than the width of one row of logos, reset the x value

    useEffect(() => {
        if (baseX.get() > width) {
            baseX.set(0)
        }
    }, [baseX, width])

    // when the x value is less than the negative width of one row of logos, reset the x value
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * (delta / 1000);
        moveBy += directionFactor.current * moveBy * baseVelocity

        baseX.set(baseX.get() + moveBy);
    })

    return (
        <div className="logo-row-loop-wrapper">
            {
                [...Array( isFinite(repeat) ? repeat : 3 )].map((_, i) => (
                    <motion.div key={ i } className="logo-row" style={{ x }}>
                        {
                            client_logos.map((logo, index) => (
                                <div key={ index } className="partner-item"><img src={ logo.attributes.url } alt="" className="partner-logo" /></div>
                            ))
                        }
                    </motion.div>
                ))
            }
        </div>
    )
}

export default ScrollingLogo

// client_logos.map((logo, index) => (
//     <div key={ index } className="partner-item"><img src={ logo.attributes.url } alt="" className="partner-logo" /></div>
// ))
