const Footer = ( { ...props } ) => {
    const footer_text = props.text ? props.text : "DRVN is a technology-agnostic digital studio, where curated professionals create amazing experiences."

    return <>
        <footer className="footer">
            <div className="padding-global">
                <div className="container-large">
                    <div className="padding-footer">
                        <div className="content-block-item">
                            <div className="w-layout-grid footer-component-grid">
                                <div id="w-node-a11e47cf-e3cb-9fd7-febf-e307090a22f3-090a22ed" className="footer-content-wrapper">
                                <a href="/" aria-current="page" className="brand-link w-inline-block w--current">
                                    <div className="logo">DRVN</div>
                                </a>
                                <div className="max-width-medium align-center">
                                    <p className="text-size-regular text-align-center">{ footer_text }</p>
                                </div>
                                {/* <div className="social-flex-wrapper">
                                    <a href="https://twitter.com/" target="_blank" className="social-link-item w-inline-block">
                                    <div className="social-icon"></div>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank" className="social-link-item w-inline-block">
                                    <div className="social-icon"></div>
                                    </a>
                                    <a href="https://dribbble.com/" target="_blank" className="social-link-item w-inline-block">
                                    <div className="social-icon"></div>
                                    </a>
                                    <a href="https://www.behance.net/" target="_blank" className="social-link-item w-inline-block">
                                    <div className="social-icon"></div>
                                    </a>
                                    <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                                    <div className="social-icon"></div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="footer-menu-wrapper">
                            <div className="footer-menu">
                                <a href="/" aria-current="page" className="footer-nav-link w-inline-block w--current">
                                    <div className="nav-text">HOME</div>
                                    <div className="menu-line"></div>
                                </a>
                                <a href="/contact" className="footer-nav-link w-inline-block">
                                    <div className="nav-text">Contact</div>
                                    <div className="menu-line"></div>
                                </a>
                                {/* <a href="/projects" className="footer-nav-link w-inline-block">
                                    <div className="nav-text">projects</div>
                                    <div className="menu-line"></div>
                                </a> */}
                                {/* <a href="/blog" className="footer-nav-link w-inline-block">
                                    <div className="nav-text">Blog</div>
                                    <div className="menu-line"></div>
                                </a> */}
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="footer-flex align-left">
                                <div className="text-size-tiny text-style-allcaps">Powered by <a href="https://react.dev/" target="_blank" className="link">React</a></div>
                            </div>
                            <div className="footer-flex align-right">
                                <div className="text-size-tiny text-style-allcaps">Made by <a href="https://drvn.be" target="_blank" className="link">DRVN</a></div>
                                <div className="text-size-tiny text-style-allcaps"><a href="/privacy" target="_blank" className="link">Privacy</a></div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}

export default Footer