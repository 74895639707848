import Topbar from '../components/Topbar'
import Footer from '../components/Footer'
import ContactCtaLarge from '../components/ContactCtaLarge'
import Loading from '../components/Loading'

import { useQuery, gql } from "@apollo/client"

const QUERY = gql`{
	privacyPolicy {
        data {
          id
          attributes {
            privacy_policy
          }
        }
      }
    homepage {
        data {
          attributes {
            footer_text
          }
        }
    }
}`

const Privacy = () => {
    const { data, loading, error } = useQuery(QUERY)

    if (loading) return <Loading />
    if (error) return <pre>{error.message}</pre>

    const privacyPolicy = data.privacyPolicy.data.attributes.privacy_policy ? data.privacyPolicy.data.attributes.privacy_policy : "Privacy Policy."
    const footer_text = data.homepage.data.attributes.footer_text ? data.homepage.data.attributes.footer_text : ""

    // privacyPolicy.map((item, index) => {
    //     console.log(item.children[0])
    // })

    return <>
        <div className="page-wrapper">
            <Topbar />
            <main className="main-wrapper">
                <header className="section-blog-content-header">
                    <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-header">
                        <div className="content-block-item">
                            <div className="margin-top margin-medium">
                            <h1 className="heading-style-h2">Privacy Policy</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </header>
                <section className="section-blog-content-article">
                    <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-small">
                        <div className="content-block-item">
                            <div className="text-rich-text w-richtext">
                                { privacyPolicy.map((item, index) => (
                                        <p key={ index }>{
                                            item.children[0].bold ? <strong>{ item.children[0].text }</strong> : item.children[0].text
                                        }</p>
                                    ))
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            <ContactCtaLarge />
            </main>
            <Footer text={ footer_text } />
        </div>
        <Topbar />
    </>
}

export default Privacy