const ProjectItem = ({ props }) => {
    const imageUrl = props.attributes.thumbnail_image.data.attributes.url ? props.attributes.thumbnail_image.data.attributes.url : ""
    const projectName = props.attributes.project_name ? props.attributes.project_name : ""
    const service = props.attributes.service ? props.attributes.service.data.attributes.service_name.replace(/_/g, ' ') : ""

    return <>
        <div role="listitem" className="w-dyn-item grayscale hover:grayscale-0">
            <a href={ `projects/${ props.id }/${ props.attributes.service.data.id }` } className="project-link-item w-inline-block">
            <div className="project-image-item"><img src={ imageUrl } alt="" className="project-image" /></div>
            <div className="project-content-block">
                <div className="w-layout-grid inner-content-grid small">
                    <div id="w-node-a35bbacf-9ed0-4444-4e62-69a4abdadce5-cb0f6d5c" className="inner-grid-block flex">
                        <div className="client-detail-flex">
                            <div className="client-name px-2 py-0.5 text-white"> { projectName } </div>
                            <div className="project-category px-2 py-0.5 text-white"> { service } </div>
                        </div>
                            <div className="arrow-circle">
                            <div className="arrow">arrow_forward</div>
                        </div>
                    </div>
                </div>
            </div>
            </a>
        </div>
    </>
}

export default ProjectItem