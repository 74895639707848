import Topbar from "../components/Topbar"
import Footer from "../components/Footer"

const Blog = () => {
    return <>
        <div className="page-wrapper">
            <Topbar />
            <div className="main-wrapper">
            <header className="section-blog-header">
                <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-header">
                    <div className="content-block-item">
                        <h1 className="heading-style-h1"><span>✺</span> Our Blog</h1>
                        <div className="margin-top margin-small">
                        <div className="max-width-medium">
                            <p className="text-size-regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, </p>
                        </div>
                        </div>
                        <div className="margin-top margin-large">
                        <div className="margin-bottom margin-small">
                            <div className="text-size-tiny text-color-gray">CATEGORIES:</div>
                        </div>
                        <div className="w-dyn-list">
                            <div role="list" className="blog-category-wrapper w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <a href="#" className="button w-button"></a>
                            </div>
                            </div>
                            <div className="empty-state w-dyn-empty">
                            <div>No items found.</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </header>
            <section className="section-blog-content">
                <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-small">
                    <div className="content-block-item">
                        <div className="w-dyn-list">
                        <div role="list" className="blog-component-grid w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                            <a data-w-id="cd624ad4-2d44-04a9-6fc7-5566fa000def" href="#" className="blog-link-item w-inline-block">
                                <div className="blog-image-wrapper"><img src="" loading="lazy" alt="" className="blog-post-image" />
                                <div className="category-tag"></div>
                                </div>
                                <div className="margin-top margin-small">
                                <div className="blog-post-content">
                                    <h2 className="heading-style-h4"></h2>
                                    <div className="margin-top margin-small">
                                    <div className="inner-grid-block flex">
                                        <div className="text-size-tiny">LEARN MORE</div>
                                        <div className="arrow-circle">
                                        <div className="arrow">arrow_forward</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="empty-state w-dyn-empty">
                            <div>No items found.</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            </div>
            <Footer />
        </div>
    </>
}

export default Blog