import ContactItem from "./ContactItem"
import LanguageContext from '../contexts/LanguageContext'
import { useContext } from 'react'

const Topbar = () => {
    const { language, changeLanguage } = useContext(LanguageContext)

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value)
    }

    return <>
        <div className="topbar">
            <div className="padding-global">
                <div className="container-large">
                    <div className="topbar-flex">
                        <a href="/" aria-current="page" className="brand-link w-inline-block w--current">
                            <div className="logo">DRVN</div>
                        </a>
                        <select value={ language } onChange={ handleLanguageChange }>
                            <option value="en">English</option>
                            <option value="nl">Nederlands</option>
                        </select>
                        <a href="/contact" className="link-button w-inline-block">
                        <div className="button-flex">
                            <div className="text-button">Contact us</div>
                            <div className="cicle-button">
                            <div className="arrow-button">keyboard_return</div>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Topbar