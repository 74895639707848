import Topbar from "../components/Topbar"
import Footer from "../components/Footer"
import ScrollingText from "../components/ScrollingText"
import Loading from '../components/Loading'
import ContactCtaLarge from '../components/ContactCtaLarge'
import VideoJS from "../components/VideoPlayer"
import CustomCarousel from "../components/CustomCarousel"
import { useParams } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import { useRef, useState, useEffect } from "react"

const Project = () => {
    const { id, serviceid } = useParams()
    const playerRef = useRef(null)
    const [ headerImage, setHeaderImage ] = useState({mime: "", url: "", loaded: false})

    const PROJECT_QUERY = gql`{
        project(id:"${ id }") {
          data {
            attributes {
              project_name
              category
              service {
                data {
                  id
                  attributes {
                    service_name
                    service_description
                  }
                }
              }
              year
              link
              overview
              challenge
              result
              quote
              tags
              header_image {
                data {
                  attributes {
                    url
                    mime
                  }
                }
              }
              thumbnail_image {
                data {
                  attributes {
                    url
                    mime
                  }
                }
              }
              media {
                data {
                  attributes {
                    url
                    mime
                  }
                }
              }
            }
          }
        }
        projects(filters: { service: { id: { eq: ${ serviceid } }}, id: { ne: ${ id } } }) {
          data {
            id
            attributes {
              project_name
            }
          }
        }
      }`

    const { data, loading, error } = useQuery(PROJECT_QUERY)

    if (loading) return <Loading />
    if (error) return <pre>{error.message}</pre>

    if ( !loading && data && !headerImage.loaded ) {
      setHeaderImage({ mime: data.project.data.attributes.header_image.data.attributes.mime, url: data.project.data.attributes.header_image.data.attributes.url, loaded: true })
    }

    const videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
        src: headerImage.mime == 'video/mp4' ? headerImage.url : "",
        type: 'video/mp4'
      }]
    }

    const handlePlayerReady = (player) => {
      playerRef.current = player
    }

    const project_name = data.project.data.attributes.project_name ? data.project.data.attributes.project_name : ""
    const category = data.project.data.attributes.category ? data.project.data.attributes.category.replace(/_/g, ' ') : ""
    const service = data.project.data.attributes.service ? data.project.data.attributes.service.data.attributes.service_name : ""
    const year = data.project.data.attributes.year ? data.project.data.attributes.year : ""
    const link = data.project.data.attributes.link ? data.project.data.attributes.link : ""
    const overview = data.project.data.attributes.overview ? data.project.data.attributes.overview : ""
    const challenge = data.project.data.attributes.challenge ? data.project.data.attributes.challenge : ""
    const result = data.project.data.attributes.result ? data.project.data.attributes.result : ""
    const media = data.project.data.attributes.media.data ? data.project.data.attributes.media.data : ""
    const quote = data.project.data.attributes.quote ? data.project.data.attributes.quote : ""
    const tags = data.project.data.attributes.tags ? data.project.data.attributes.tags : null

    const related_projects = data.projects.data ? data.projects.data : null
    // console.log(related_projects)

    return <>
        <div className="page-wrapper">
            <Topbar />
            <div className="main-wrapper">
            <header className="section-project-header">
                <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-header">
                    <div className="content-block-item">
                        <div className="horizontal-flex">
                        <h2><span>✺</span> { project_name }</h2>
                        <h1 className="heading-style-h2"></h1>
                        </div>
                        <div className="margin-top margin-small">
                        <div className="text-size-medium"></div>
                        </div>
                        <div className="margin-top margin-xlarge">
                        <div className={ link === "" ? 'w-layout-grid project-details-component-grid-3' : 'w-layout-grid project-details-component-grid' }>
                            <div className="project-details-content">
                              <div className="text-size-small text-style-allcaps text-color-gray">Category</div>
                              <div className="text-size-medium">{ category }</div>
                            </div>
                            <div className="project-details-content">
                              <div className="text-size-small text-style-allcaps text-color-gray">Service</div>
                              <div className="text-size-medium">{ service }</div>
                            </div>
                            <div className="project-details-content">
                              <div className="text-size-small text-style-allcaps text-color-gray">year</div>
                              <div className="text-size-medium">{ year }</div>
                            </div>
                              {
                                link === "" ? null :
                                <>
                                  <div className="project-details-content">
                                    <div className="text-size-small text-style-allcaps text-color-gray">website</div>
                                    <a href={ link } target="_blank" className="client-website-link w-inline-block">
                                        <div className="text-size-medium">See live</div>
                                        <div className="website-arrow">open_in_new</div>
                                    </a>
                                  </div>
                                </>
                              }
                        </div>
                        </div>
                        <div className="margin-top margin-large">
                          { headerImage.mime.includes("video") ? <VideoJS options={ videoJsOptions } onReady={ handlePlayerReady } /> : null }
                          { headerImage.mime.includes("image") ? <img src={ headerImage.url } alt="" className="project-image" /> : null }
                          { headerImage.mime === "" ? <div>Loading ...</div> : null }
                        </div>
                        <div className="margin-top margin-medium">
                          <div className="blog-post-details-flex">
                            {
                              tags ?
                              tags.map((item, index) => (
                                <div key={ index } className="category">{ item }</div>
                              ))
                              :
                              null
                            }
                          </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </header>
            <ScrollingText baseVelocity={ 1 }>{ service }</ScrollingText>
            <section className="section-project-content">
                <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-small">
                    <div className="content-block-item">
                        <div>
                          <h3>Overview</h3>
                          <div className="text-lg font-light margin-bottom margin-medium">{ overview }</div>
                          { media ?
                            <CustomCarousel>
                              { media.map((image, index) => {
                                return <img key={ index }  src={ image.attributes.url } alt={ project_name } />
                              }) }
                            </CustomCarousel>
                            :
                            null
                          }
                        </div>
                        <div>
                          <h3>Challenge</h3>
                          <div className="text-lg font-light margin-bottom margin-medium">{ challenge }</div>
                        </div>
                        <blockquote className="margin-bottom margin-medium">{ quote }</blockquote>
                        <div>
                          <h3>Result</h3>
                          <div className="text-lg font-light margin-bottom margin-medium">{ result }</div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <ContactCtaLarge />
            </div>
            <Footer />
        </div>
    </>
}

export default Project