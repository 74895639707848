import { useState } from 'react'

const Form = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [response, setResponse] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        // alert(`The name you entered was: ${name}, ${email}, ${subject}, ${message}`)
        const form = e.target
        // const formData = new FormData(form)

        const data = new FormData(form)
        const json = Object.fromEntries(data.entries())
        console.log(json)

        const toSubmit = {
            "fields": [
                {
                    "name": "email",
                    "value": json.email
                },
                {
                    "name": "firstname",
                    "value": json.firstname
                },
                {
                    "name": "lastname",
                    "value": json.lastname
                },
                {
                    "name": "subject",
                    "value": json.subject
                },
                {
                    "name": "message",
                    "value": json.message
                },
            ]
        }

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/8571490/0ecf1e12-726e-4f1a-81c1-1dc9db830293', {
            method: form.method,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(toSubmit)})
            .then(response => response.json())
            .then(data => console.log("Done!", data))
            .then(() => setResponse(true))
            .catch(error => setError(true))

        form.reset()
    }

    return <>
        <div className="form-block w-form">
            <form onSubmit={ handleSubmit } id="email-form" name="email-form" data-name="Email Form" method="post" className="form-wrapper">
                <div className="w-layout-grid form-component-grid">
                    <div className="form-inline-fields">
                        <div className="form-inline-fields-field">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input onChange={(e) => setFirstName(e.target.value)} type="text" className="form-input w-input" maxLength="256" name="firstname" placeholder="" id="firstName" />
                        </div>
                        <div className="form-inline-fields-field">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input onChange={(e) => setLastName(e.target.value)} type="text" className="form-input w-input" maxLength="256" name="lastname" placeholder="" id="lastName" />
                        </div>
                    </div>
                    <div><label htmlFor="email" className="form-label">Email Address</label><input onChange={(e) => setEmail(e.target.value)} type="email" className="form-input w-input" maxLength="256" name="email" placeholder="" id="email" required="" /></div>
                    <div><label htmlFor="Subject" className="form-label">Subject</label><input onChange={(e) => setSubject(e.target.value)} type="text" className="form-input w-input" maxLength="256" name="subject" placeholder="" id="Subject" required="" /></div>
                    <div><label htmlFor="Message" className="form-label">Message</label><input onChange={(e) => setMessage(e.target.value)} type="text" className="form-input is-text-area w-input" maxLength="256" name="message" placeholder="" id="Message" required="" /></div>
                </div>
                <div className="margin-top margin-large">
                    <input type="submit" value="Send message" data-wait="Please wait..." className="button w-button" />
                </div>
            </form>
            {
                response ? <div className="form-message-success w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div> : null
            }
            {
                error ? <div className="form-message-error w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div> : null
            }
        </div>
    </>
}

export default Form