// src/components/LanguageProvider.jsx
import React, { useState, useEffect } from 'react'
import LanguageContext from '../contexts/LanguageContext'

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    useEffect(() => {
        // Update local storage when language changes
        localStorage.setItem('language', language);
    }, [language])

    const changeLanguage = (lang) => {
        setLanguage(lang)
    }

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider
