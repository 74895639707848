import Topbar from '../components/Topbar'
import Footer from '../components/Footer'
import ContactCta from '../components/ContactCta'

const Projects = () => {
  return <>
    <Topbar />
    <div class="main-wrapper">
      <section class="section-all-projects">
        <div class="padding-global">
          <div class="container-large">
            <div class="padding-section-header">
              <div class="content-block-item">
                <h1 class="heading-style-h1"><span>✺</span> Selected projects</h1>
                <div class="margin-top margin-large">
                  <div class="w-dyn-list">
                    <div role="list" class="projects-grid w-dyn-items">
                      <div role="listitem" class="w-dyn-item">
                        <a href="#" class="project-link-item w-inline-block">
                          <div class="project-image-item"><img src="" alt="" class="project-image" /></div>
                          <div class="project-content-block">
                            <div class="w-layout-grid inner-content-grid small">
                              <div id="w-node-_841a5b66-cb4d-6d35-f39a-4978aea9ab6f-cb0f6d67" class="inner-grid-block flex">
                                <div class="client-detail-flex">
                                  <div class="client-name"></div>
                                  <div class="project-category"></div>
                                </div>
                                <div class="arrow-circle">
                                  <div class="arrow">arrow_forward</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="empty-state w-dyn-empty">
                      <div>No items found.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-home-call-to-action">
        <div class="padding-global">
          <div class="container-large">
            <div class="padding-section-small">
              <div class="content-block-item">
                <div class="w-layout-grid call-to-action-component-grid">
                  <div id="w-node-b0267233-5fed-c2ba-59d4-08b08ca9b732-8ca9b72c" class="inner-grid-block">
                    <div class="cta-wrapper-flex">
                      <ContactCta />
                    </div>
                  </div>
                  <div id="w-node-b0267233-5fed-c2ba-59d4-08b08ca9b739-8ca9b72c" class="cta-flex">
                    <h2 class="heading-style-h1 text-align-center"><span class="asterisk">✺</span> </h2>
                    <h2 class="heading-style-h1 text-align-center">Let&#x27;s work together</h2>
                    <h2 class="heading-style-h1 text-align-center"><span class="asterisk">✺</span> </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>
}

export default Projects