import React from 'react'
import ReactDOM from 'react-dom/client'
import Wrapper from './components/Wrapper.jsx'

import LanguageProvider from './components/LanguageProvider'; // Import the provider

import './normalize.css'
import './wf.css'
import './index.css'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
    <LanguageProvider>
            <Wrapper />
    </LanguageProvider>
    </>
)