import Topbar from '../components/Topbar'
import Footer from '../components/Footer'
import Form from '../components/Form'

const Contact = () => {
    return <>
        <Topbar />
        <div className="main-wrapper">
        <header className="section-contact-header">
            <div className="padding-global">
            <div className="container-small">
                <div className="padding-section-header">
                <div className="content-block-item">
                    <h1 className="heading-style-h1"><span>✺</span> Contact us</h1>
                    <div className="margin-top margin-small">
                    <div className="max-width-medium">
                        <p className="text-size-regular">In an age where AI and digital innovations shape our world, choose DRVN as your trusted guide. From web development to 3D visuals, we've got you covered. Ready to embark on a transformative journey? Fill out our contact form and let's drive forward, together.</p>
                    </div>
                    </div>
                    <div className="margin-top margin-large">
                    <div className="contact-social-wrapper">
                        <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                        <div className="social-icon"></div>
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                        <div className="social-icon"></div>
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                        <div className="social-icon"></div>
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                        <div className="social-icon"></div>
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" className="social-link-item w-inline-block">
                        <div className="social-icon"></div>
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </header>
        <section className="section-contact-form">
            <div className="padding-global">
            <div className="container-small">
                <div className="padding-section-small">
                <div className="content-block-item">
                    <div className="margin-top margin-large">
                        <Form />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </div>
        <Footer />
    </>
}

export default Contact